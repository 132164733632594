import Role from '@/business/dto/types/role';
import useAuth from '@/contexts/use-auth';
import { Link, LinkProps } from 'react-router-dom';

const AdminLink = (
  props: LinkProps & React.RefAttributes<HTMLAnchorElement>
) => {
  const auth = useAuth();

  return (
    <>
      {auth.user.role === Role.owner || auth.user.role === Role.admin ? (
        <Link {...props}>{props.children}</Link>
      ) : (
        <span className={props.className}>{props.children}</span>
      )}
    </>
  );
};

export default AdminLink;
