import { loadCompany } from '@/business/api/company.service'
import CompanyPopulatedResponse from '@/business/dto/responses/company-populated.response'
import Role from '@/business/dto/types/role'
import UiContainer from '@/components/ui-kit/layout/UiContainer'
import UiHeader from '@/components/ui-kit/layout/UiHeader'
import UiVerticalNavigation from '@/components/ui-kit/layout/UiVerticalNavigation'
import useAuth from '@/contexts/use-auth'
import {
  BellIcon,
  BookmarkSquareIcon,
  BuildingOffice2Icon,
  ComputerDesktopIcon,
  CreditCardIcon,
  GlobeEuropeAfricaIcon,
  MapPinIcon,
  WrenchScrewdriverIcon,
} from '@heroicons/react/24/outline'
import { ElementType, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet, useLoaderData, useOutletContext } from 'react-router-dom'

export const companyPageLoader = async () => loadCompany()

const CompanyPage = () => {
  const auth = useAuth()
  const { t } = useTranslation()
  const [company, setCompany] = useState<CompanyPopulatedResponse>(
    useLoaderData() as CompanyPopulatedResponse
  )

  const getWeekdayLabel = (index: number) => {
    if (index === 0) return t('common.sunday')
    if (index === 1) return t('common.monday')
    if (index === 2) return t('common.tuesday')
    if (index === 3) return t('common.wednesday')
    if (index === 4) return t('common.thursday')
    if (index === 5) return t('common.friday')
    if (index === 6) return t('common.saturday')
  }

  const tabs = [
    {
      name: t('common.general'),
      description: `resflow.com/${company.publicId}`,
      icon: BuildingOffice2Icon,
      href: `/properties`,
      end: true,
    },
    // {
    //   name: 'Globale Abwesenheiten',
    //   description: `${
    //     company.availabilities?.length ? company.availabilities?.length : 0
    //   } Verfügbarkeiten, ${
    //     company.exceptions?.length ? company.exceptions?.length : 0
    //   } Ausnahmen`,
    //   icon: CalendarDaysIcon,
    //   href: '/availabilities',
    //   end: true,
    // },
    {
      name: t('common.location'),
      description: [
        company.location.line1,
        company.location.line2,
        company.location.postalCode,
        company.location.city,
        company.location.country,
      ]
        .filter((x) => x && x.length > 0)
        .join(', '),
      icon: MapPinIcon,
      href: `/location`,
      end: true,
    },
    {
      name: t('common.languageAndRegion'),
      description: `${company.localization.language}, ${
        company.localization.timeZone
      }, ${getWeekdayLabel(
        company.localization.weekStartsOn
      )}`,
      icon: GlobeEuropeAfricaIcon,
      href: `/language`,
      end: true,
    },
    {
      name: 'Weiterführendes',
      description:
        company.legal.notice || company.legal.privacy || company.legal.terms
          ? `${company.legal.notice ? `${company.legal.notice},` : ''} ${
              company.legal.terms ? `${company.legal.terms},` : ''
            } ${company.legal.privacy ? `${company.legal.privacy},` : ''}`
          : 'Noch keine rechtlichen Angaben',
      icon: BookmarkSquareIcon,
      href: `/legal`,
      end: true,
    },
    // {
    //   name: t('common.appearance'),
    //   description: `${
    //     company.appearance.theme === Theme.light
    //       ? 'Hell'
    //       : company.appearance.theme === Theme.dark
    //       ? 'Dunkel'
    //       : 'Systemabhängig'
    //   }, ${company.appearance.color}`,
    //   icon: SwatchIcon,
    //   href: `/appearance`,
    //   end: true,
    // },
    {
      name: 'Industry settings',
      description: 'Keine',
      icon: WrenchScrewdriverIcon,
      href: `/defaults`,
      end: true,
    },
    {
      name: 'Your resflow Page',
      description: 'Keine',
      icon: ComputerDesktopIcon,
      href: `/booking`,
      end: true,
    },
    {
      name: t('common.notifications'),
      description: `${[
        company.notifications.sendConfirmation ? 'Bestätigung' : null,
        company.notifications.sendReminder ? 'Erinnerung' : null,
        company.notifications.sendCancellation ? 'Stornierung' : null,
        company.notifications.sendFollowUp ? 'Follow-Up' : null,
      ]
        .filter((x) => x)
        .join(', ')}`,
      icon: BellIcon,
      href: `/notifications`,
      end: true,
    },
  ]

  useEffect(() => auth.setCompany(company), [company])

  if (auth.user.role === Role.owner) {
    tabs.push({
      name: t('common.plansAndBilling'),
      description: t(`product.${auth.company.subscription!.productId}`),
      icon: CreditCardIcon,
      href: `/contract`,
      end: true,
    })
  }

  return (
    <UiContainer>
      <UiHeader
        className="hidden md:block"
        avatar={{
          name: company.name,
          color: company.appearance.color,
          image: company.image,
          shape: 'rounded',
        }}
        title={company.name}
        description={t('page.description.company')}
        mobileBackHref="/settings"
      />
      <div className="md:py-8">
        <div className="md:grid grid-cols-12 md:gap-x-6">
          <aside className="hidden md:block col-span-12 lg:col-span-3 mb-6">
            <UiVerticalNavigation>
              {tabs.map((tab) => (
                <UiVerticalNavigation.Item
                  key={tab.name}
                  title={tab.name}
                  subtitle={tab.description}
                  icon={tab.icon}
                  href={`/company${tab.href}`}
                  end={tab.end}
                />
              ))}
            </UiVerticalNavigation>
          </aside>
          <div className="col-span-12 lg:col-span-9">
            <Outlet
              context={{
                company: company,
                setCompany: setCompany,
                tabs,
              }}
            />
          </div>
        </div>
      </div>
    </UiContainer>
  )
}

type ContextType = {
  company: CompanyPopulatedResponse
  setCompany: React.Dispatch<React.SetStateAction<CompanyPopulatedResponse>>
  tabs: {
    name: string
    description: string
    href: string
    icon: ElementType
    end: boolean
  }[]
}

export default CompanyPage

export function useCompany() {
  return useOutletContext<ContextType>()
}
