import {
  createBrowserRouter,
  Navigate,
  Outlet,
  redirect,
  RouterProvider,
} from 'react-router-dom'
import { Suspense } from 'react'
import { Toaster } from 'react-hot-toast'
import './App.css'
import InternalLayout from './components/InternalLayout'
import DashboardPage from './pages/dashboard/Dashboard'
import ReservationsPage, {
  reservationsLoader,
} from './pages/reservations/Reservations'
import ContactsPage, { contactsPageLoader } from './pages/contacts/Contacts'
import UsersPage, { usersPageLoader } from './pages/users/Users'
import IntegrationsPage from './pages/integrations/Integrations'
import CompanyPage, { companyPageLoader } from './pages/company/Company'
import ServicesPage, { searvicesPageLoader } from './pages/services/Services'
import ResourcesPage, { resourcesPageLoader } from './pages/resources/Resources'
import SignInPage from './pages/public/SignIn'
import SignUpPage from './pages/public/SignUp'
import ServicePage, { servicePageLoader } from './pages/services/Service'
import ResourcePage, { resourcePageLoader } from './pages/resources/Resource'
import UserPage, {
  accountPageLoader,
  employeePageLoader,
  userPageLoader,
} from './pages/users/User'
import CompanyGeneralPage from './pages/company/CompanyGeneral'
import CompanyLocationPage from './pages/company/CompanyLocation'
import CompanyAppearancePage from './pages/company/CompanyAppearance'
import CompanyDefaultsPage from './pages/company/CompanyDefaults'
import CompanyNotificationsPage from './pages/company/CompanyNotifications'
import axios, { AxiosError } from 'axios'
import SettingsMobilePage from './pages/settings/SettingsMobile'
import CompanyLanguagePage from './pages/company/CompanyLanguage'
import ErrorBoundary from './components/ErrorBoundary'
import GlobalLayout from './components/GlobalLayout'
import VerifyPage from './pages/public/Verify'
import ForgotPasswordPage from './pages/public/ForgotPassword'
import CompanyContractPage, {
  CompanyContractPageLoader,
} from './pages/company/CompanyContract'
import ContactPage, { contactPageLoader } from './pages/contacts/Contact'
import PublicLayout from './components/PublicLayout'
import CompanyAvailabilityPage from './pages/company/CompanyAvailability'
import ResetPasswordPage from './pages/public/ResetPassword'
import CategoriesPage, {
  categoriesPageLoader,
} from './pages/categories/Categories'
import CategoryPage, { categoryPageLoader } from './pages/categories/Category'
import StaffLayout from './pages/staff/Layout'
import AuthProvider, { authLoader } from './components/AuthProvider'
import EmployeesPage, { employeesLoader } from './pages/staff/Employees'
import ResflowCompaniesPage, { companiesLoader } from './pages/staff/Companies'
import CompanyLegalPage from './pages/company/CompanyLegal'
import CompanyMobilePage from './pages/company/CompanyMobile'
import ResourceNewPage from './pages/resources/ResourceNew'
import ResourceEditPage, {
  resourceEditPageLoader,
} from './pages/resources/ResourceEdit'
import ServiceNewPage from './pages/services/ServiceNew'
import UserNewPage from './pages/users/UserNew'
import ContactNewPage from './pages/contacts/ContactNew'
import UserEditPage, {
  accountEditPageLoader,
  userEditPageLoader,
} from './pages/users/UserEdit'
import ServiceEditPage, {
  serviceEditPageLoader,
} from './pages/services/ServiceEdit'
import ContactEditPage, {
  contactEditPageLoader,
} from './pages/contacts/ContactEdit'
import CategoryEditPage, {
  categoryEditLoader,
} from './pages/categories/CategoryEdit'
import CategoryNewPage from './pages/categories/CategoryNew'
import ServiceAvailabilityNewPage, {
  serviceAvailabilityNewPageLoader,
} from './pages/services/ServiceAvailabilityNew'
import ServiceAvailabilityEditPage, {
  serviceAvailabilityEditPageLoader,
} from './pages/services/ServiceAvailabilityEdit'
import ServiceExceptionEditPage, {
  serviceExceptionEditPageLoader,
} from './pages/services/ServiceExceptionEdit'
import ServiceExceptionNewPage, {
  serviceExceptionNewPageLoader,
} from './pages/services/ServiceExceptionNew'
import ServiceResourcesAddPage, {
  serviceResourcesAddPageLoader,
} from './pages/services/ServiceResourcesAdd'
import ServiceQuestionsNewPage, {
  serviceQuestionsNewPageLoader,
} from './pages/services/ServiceQuestionsNew'
import ServiceQuestionsEditPage, {
  serviceQuestionsEditPageLoader,
} from './pages/services/ServiceQuestionsEdit'
import ResourceServicesAddPage, {
  resourceServicesAddPageLoader,
} from './pages/resources/ResourceServicesAdd'
import ServiceCategoriesAddPage, {
  serviceCategoriesAddPageLoader,
} from './pages/services/ServiceCategoriesAdd'
import CategoryServicesAddPage, {
  categoryServicesAddPageLoader,
} from './pages/categories/CategoryServicesAdd'
import ResourceAvailabilityNewPage, {
  resourceAvailabilityNewPageLoader,
} from './pages/resources/ResourceAvailabilityNew'
import ResourceAvailabilityEditPage, {
  resourceAvailabilityEditPageLoader,
} from './pages/resources/ResourceAvailabilityEdit'
import ResourceExceptionNewPage, {
  resourceExceptionNewPageLoader,
} from './pages/resources/ResourceExceptionNew'
import ResourceExceptionEditPage, {
  resourceExceptionEditPageLoader,
} from './pages/resources/ResourceExceptionEdit'
import EmployeeNewPage from './pages/staff/EmployeeNew'
import ReservationNewPage, {
  reservationNewPageLoader,
} from './pages/reservations/ReservationNew'
import CompanyBookingPage from './pages/company/CompanyBooking'
import ReservationPage, {
  reservationPageLoader,
} from './pages/reservations/Reservation'
import { obtainToken } from './business/api/auth.service'

const App = () => {
  const date = new Date()
  console.log('date', date)
  console.log('date time', date.getTime())

  const redirectToSignIn = () => {
    localStorage.removeItem('token_type')
    localStorage.removeItem('access_token')
    localStorage.removeItem('refresh_token')
    localStorage.removeItem('resflow_staff_token_type')
    localStorage.removeItem('resflow_staff_access_token')
    localStorage.removeItem('resflow_staff_refresh_token')

    throw redirect(
      `/sign-in${
        window.location.pathname !== '' && window.location.pathname !== '/'
          ? `?redirect=${window.location.pathname}`
          : ''
      }`
    )
  }

  const convertEmptyStringsToNull = (object: any) => {
    for (const key in object) {
      if (object.hasOwnProperty(key)) {
        if (typeof object[key] === 'object' && object[key] !== null) {
          convertEmptyStringsToNull(object[key]) // Rekursiv für verschachtelte Objekte aufrufen
        } else if (object[key] === '') {
          object[key] = null // Leere Zeichenketten in null umwandeln
        }
      }
    }
  }

  axios.interceptors.request.use(
    (config) => {
      const accessToken = localStorage.getItem('access_token')
      if (config.headers && accessToken) {
        config.headers['Authorization'] = `Bearer ${accessToken}`
        config.headers['Content-Type'] = 'application/json'
      }

      if (config.data) {
        convertEmptyStringsToNull(config.data)
      }

      return config
    },
    (error) => {
      return Promise.reject(error)
    }
  )

  axios.interceptors.response.use(
    (response) => {
      return response
    },
    async (error) => {
      const originalRequest = error.config

      if (originalRequest.url === '/auth/token') {
        // refresh token is invalid, not there, whatever
        redirectToSignIn()
      }

      if (error.response.status === 401 && !originalRequest._retry) {
        // first time unauthorized request sent
        originalRequest._retry = true

        // try to use refresh token to get a new access token
        const refreshToken = localStorage.getItem('refresh_token')
        if (refreshToken) {
          return obtainToken({ refreshToken })
            .then((response: any) => {
              localStorage.setItem('token_type', response.tokenType)
              localStorage.setItem('access_token', response.accessToken)
              localStorage.setItem('refresh_token', response.refreshToken)
              console.log('resent request')
              return axios(originalRequest)
            })
            .catch((error: AxiosError) => {
              redirectToSignIn()
              /*
              if (error.response?.status === 401) {
                // means: no active subscription
                redirectToSignIn()
              } else {
                // other error than 404 ???
                return Promise.reject(error) // why not just redirect to sign in?
              }
              */
            })
        } else {
          // no refresh token... redirect to sign in
          redirectToSignIn()
        }
      }

      // if (
      //   error.response.status === 403 &&
      //   error.response.data &&
      //   error.response.data.message === 'Subscription required.'
      // ) {
      //   // subscription required, may only happen when the company is in the delete process
      //   redirectToSignIn()
      // }

      return Promise.reject(error)
    }
  )

  return (
    <>
      <Suspense fallback={<div></div>}>
        <Toaster position='bottom-center' />
        <RouterProvider
          router={createBrowserRouter([
            {
              path: '/',
              element: <GlobalLayout />,
              errorElement: <ErrorBoundary />,
              children: [
                {
                  path: '/',
                  element: <PublicLayout />,
                  errorElement: <ErrorBoundary />,
                  children: [
                    { path: 'sign-in', element: <SignInPage /> },
                    { path: 'sign-up', element: <SignUpPage /> },
                    {
                      path: 'verify',
                      element: <VerifyPage />,
                    },
                    {
                      path: 'forgot-password',
                      element: <ForgotPasswordPage />,
                    },
                    { path: 'reset-password', element: <ResetPasswordPage /> },
                  ],
                },
                {
                  path: '/',
                  element: <AuthProvider />,
                  loader: authLoader,
                  errorElement: <ErrorBoundary />,
                  children: [
                    {
                      path: '/',
                      element: <InternalLayout />,
                      children: [
                        {
                          index: true,
                          element: <DashboardPage />,
                          errorElement: <ErrorBoundary />,
                        },
                        {
                          path: 'reservations',
                          children: [
                            {
                              index: true,
                              element: <ReservationsPage />,
                              loader: reservationsLoader,
                              errorElement: <ErrorBoundary />,
                            },
                            {
                              path: 'new',
                              element: <ReservationNewPage />,
                              loader: reservationNewPageLoader,
                              errorElement: <ErrorBoundary />,
                            },
                            {
                              path: ':reservationId',
                              children: [
                                {
                                  index: true,
                                  element: <ReservationPage />,
                                  loader: reservationPageLoader,
                                  errorElement: <ErrorBoundary />,
                                },
                                // {
                                //   path: 'edit',
                                //   element: <ReservationEditPage />,
                                //   loader: reservationEditPageLoader,
                                //   errorElement: <ErrorBoundary />,
                                // },
                              ],
                            },
                          ],
                        },
                        {
                          path: 'contacts',
                          children: [
                            {
                              index: true,
                              element: <ContactsPage />,
                              loader: contactsPageLoader,
                              errorElement: <ErrorBoundary />,
                            },
                            {
                              path: 'new',
                              element: <ContactNewPage />,
                              errorElement: <ErrorBoundary />,
                            },
                            {
                              path: ':contactId',
                              children: [
                                {
                                  index: true,
                                  element: <ContactPage />,
                                  loader: contactPageLoader,
                                  errorElement: <ErrorBoundary />,
                                },
                                {
                                  path: 'edit',
                                  element: <ContactEditPage />,
                                  loader: contactEditPageLoader,
                                  errorElement: <ErrorBoundary />,
                                },
                              ],
                            },
                          ],
                        },
                        {
                          path: 'company',
                          element: <CompanyPage />,
                          loader: companyPageLoader,
                          errorElement: <ErrorBoundary />,
                          id: 'company',
                          children: [
                            {
                              index: true,
                              element: <CompanyMobilePage />,
                            },
                            {
                              path: 'properties',
                              element: <CompanyGeneralPage />,
                            },
                            {
                              path: 'availabilities',
                              element: <CompanyAvailabilityPage />,
                            },
                            {
                              path: 'location',
                              element: <CompanyLocationPage />,
                            },
                            {
                              path: 'legal',
                              element: <CompanyLegalPage />,
                            },
                            {
                              path: 'appearance',
                              element: <CompanyAppearancePage />,
                            },
                            {
                              path: 'defaults',
                              element: <CompanyDefaultsPage />,
                            },
                            {
                              path: 'booking',
                              element: <CompanyBookingPage />,
                            },
                            {
                              path: 'notifications',
                              element: <CompanyNotificationsPage />,
                            },
                            {
                              path: 'language',
                              element: <CompanyLanguagePage />,
                            },
                            {
                              path: 'contract',
                              element: <CompanyContractPage />,
                              loader: CompanyContractPageLoader,
                              errorElement: <ErrorBoundary />,
                            },
                          ],
                        },
                        {
                          path: 'account',
                          children: [
                            {
                              index: true,
                              element: <UserPage />,
                              loader: accountPageLoader,
                              errorElement: <ErrorBoundary />,
                            },
                            {
                              path: 'edit',
                              element: <UserEditPage />,
                              loader: accountEditPageLoader,
                              errorElement: <ErrorBoundary />,
                            },
                          ],
                        },
                        {
                          path: 'users',
                          children: [
                            {
                              index: true,
                              element: <UsersPage />,
                              loader: usersPageLoader,
                              errorElement: <ErrorBoundary />,
                            },
                            {
                              path: 'new',
                              element: <UserNewPage />,
                              errorElement: <ErrorBoundary />,
                            },
                            {
                              path: ':userId',
                              children: [
                                {
                                  index: true,
                                  element: <UserPage />,
                                  loader: userPageLoader,
                                  errorElement: <ErrorBoundary />,
                                },
                                {
                                  path: 'edit',
                                  element: <UserEditPage />,
                                  loader: userEditPageLoader,
                                  errorElement: <ErrorBoundary />,
                                },
                              ],
                            },
                          ],
                        },
                        {
                          path: 'categories',
                          children: [
                            {
                              index: true,
                              element: <CategoriesPage />,
                              loader: categoriesPageLoader,
                              errorElement: <ErrorBoundary />,
                            },
                            {
                              path: 'new',
                              element: <CategoryNewPage />,
                              errorElement: <ErrorBoundary />,
                            },
                            {
                              path: ':categoryId',
                              children: [
                                {
                                  index: true,
                                  element: <CategoryPage />,
                                  loader: categoryPageLoader,
                                  errorElement: <ErrorBoundary />,
                                },
                                {
                                  path: 'edit',
                                  element: <CategoryEditPage />,
                                  loader: categoryEditLoader,
                                  errorElement: <ErrorBoundary />,
                                },
                                {
                                  path: 'services',
                                  children: [
                                    {
                                      index: true,
                                      element: <CategoryServicesAddPage />,
                                      loader: categoryServicesAddPageLoader,
                                      errorElement: <ErrorBoundary />,
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          path: 'services',
                          children: [
                            {
                              index: true,
                              element: <ServicesPage />,
                              loader: searvicesPageLoader,
                              errorElement: <ErrorBoundary />,
                            },
                            {
                              path: 'new',
                              element: <ServiceNewPage />,
                              errorElement: <ErrorBoundary />,
                            },
                            {
                              path: ':serviceId',
                              children: [
                                {
                                  index: true,
                                  element: <ServicePage />,
                                  loader: servicePageLoader,
                                  errorElement: <ErrorBoundary />,
                                },
                                {
                                  path: 'edit',
                                  element: <ServiceEditPage />,
                                  loader: serviceEditPageLoader,
                                  errorElement: <ErrorBoundary />,
                                },
                                {
                                  path: 'availabilities',
                                  children: [
                                    {
                                      index: true,
                                      element: <ServiceAvailabilityNewPage />,
                                      loader: serviceAvailabilityNewPageLoader,
                                      errorElement: <ErrorBoundary />,
                                    },
                                    {
                                      path: ':availabilityId',
                                      element: <ServiceAvailabilityEditPage />,
                                      loader: serviceAvailabilityEditPageLoader,
                                      errorElement: <ErrorBoundary />,
                                    },
                                  ],
                                },
                                {
                                  path: 'exceptions',
                                  children: [
                                    {
                                      index: true,
                                      element: <ServiceExceptionNewPage />,
                                      loader: serviceExceptionNewPageLoader,
                                      errorElement: <ErrorBoundary />,
                                    },
                                    {
                                      path: ':exceptionId',
                                      element: <ServiceExceptionEditPage />,
                                      loader: serviceExceptionEditPageLoader,
                                      errorElement: <ErrorBoundary />,
                                    },
                                  ],
                                },
                                {
                                  path: 'categories',
                                  children: [
                                    {
                                      index: true,
                                      element: <ServiceCategoriesAddPage />,
                                      loader: serviceCategoriesAddPageLoader,
                                      errorElement: <ErrorBoundary />,
                                    },
                                  ],
                                },
                                {
                                  path: 'resources',
                                  children: [
                                    {
                                      index: true,
                                      element: <ServiceResourcesAddPage />,
                                      loader: serviceResourcesAddPageLoader,
                                      errorElement: <ErrorBoundary />,
                                    },
                                  ],
                                },
                                {
                                  path: 'questions',
                                  children: [
                                    {
                                      index: true,
                                      element: <ServiceQuestionsNewPage />,
                                      loader: serviceQuestionsNewPageLoader,
                                      errorElement: <ErrorBoundary />,
                                    },
                                    {
                                      path: ':exceptionId',
                                      element: <ServiceQuestionsEditPage />,
                                      loader: serviceQuestionsEditPageLoader,
                                      errorElement: <ErrorBoundary />,
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          path: 'resources',
                          children: [
                            {
                              index: true,
                              element: <ResourcesPage />,
                              loader: resourcesPageLoader,
                              errorElement: <ErrorBoundary />,
                              id: 'resources',
                            },
                            {
                              path: 'new',
                              element: <ResourceNewPage />,
                              errorElement: <ErrorBoundary />,
                            },
                            {
                              path: ':resourceId',
                              children: [
                                {
                                  index: true,
                                  element: <ResourcePage />,
                                  loader: resourcePageLoader,
                                  errorElement: <ErrorBoundary />,
                                },
                                {
                                  path: 'edit',
                                  element: <ResourceEditPage />,
                                  loader: resourceEditPageLoader,
                                  errorElement: <ErrorBoundary />,
                                },
                                {
                                  path: 'availabilities',
                                  children: [
                                    {
                                      index: true,
                                      element: <ResourceAvailabilityNewPage />,
                                      loader: resourceAvailabilityNewPageLoader,
                                      errorElement: <ErrorBoundary />,
                                    },
                                    {
                                      path: ':availabilityId',
                                      element: <ResourceAvailabilityEditPage />,
                                      loader:
                                        resourceAvailabilityEditPageLoader,
                                      errorElement: <ErrorBoundary />,
                                    },
                                  ],
                                },
                                {
                                  path: 'exceptions',
                                  children: [
                                    {
                                      index: true,
                                      element: <ResourceExceptionNewPage />,
                                      loader: resourceExceptionNewPageLoader,
                                      errorElement: <ErrorBoundary />,
                                    },
                                    {
                                      path: ':exceptionId',
                                      element: <ResourceExceptionEditPage />,
                                      loader: resourceExceptionEditPageLoader,
                                      errorElement: <ErrorBoundary />,
                                    },
                                  ],
                                },
                                {
                                  path: 'services',
                                  children: [
                                    {
                                      index: true,
                                      element: <ResourceServicesAddPage />,
                                      loader: resourceServicesAddPageLoader,
                                      errorElement: <ErrorBoundary />,
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                        { path: 'integrations', element: <IntegrationsPage /> },
                        // { path: 'recommend', element: <RecommendPage /> },
                        { path: 'settings', element: <SettingsMobilePage /> },
                      ],
                    },
                    {
                      path: '/',
                      element: <StaffLayout />,
                      children: [
                        // {
                        //   index: true,
                        //   element: <ResflowDashboardPage />,
                        //   // loader: usersLoader,
                        //   // errorElement: <ErrorBoundary />,
                        // },
                        {
                          path: 'employees',
                          children: [
                            {
                              index: true,
                              element: <EmployeesPage />,
                              loader: employeesLoader,
                              errorElement: <ErrorBoundary />,
                              id: 'employees',
                            },
                            {
                              path: 'new',
                              element: <EmployeeNewPage />,
                              errorElement: <ErrorBoundary />,
                            },
                            {
                              path: ':employeeId',
                              children: [
                                {
                                  index: true,
                                  element: <UserPage />,
                                  loader: employeePageLoader,
                                  errorElement: <ErrorBoundary />,
                                  id: 'employee',
                                },
                                {
                                  path: 'edit',
                                  element: <UserEditPage />,
                                  loader: employeePageLoader,
                                  errorElement: <ErrorBoundary />,
                                  id: 'employeeEdit',
                                },
                              ],
                            },
                          ],
                        },
                        {
                          path: 'companies',
                          element: <ResflowCompaniesPage />,
                          loader: companiesLoader,
                          errorElement: <ErrorBoundary />,
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              path: '*',
              element: <Navigate to='/' replace={true} />,
            },
          ])}
        />
      </Suspense>
    </>
  )
}

export default App
