import { loadAuthPopulated } from '@/business/api/auth.service'
import { findUserByIdPopulated } from '@/business/api/user.service'
import UserPopulatedResponse from '@/business/dto/responses/user-populated.response'
import UserDangerzoneCard from '@/components/cards/UserDangerzoneCard'
import UserGeneralCard from '@/components/cards/UserGeneralCard'
import UserMetadataCard from '@/components/cards/UserMetadataCard'
import UserSecurityCard from '@/components/cards/UserSecurityCard'
import UiButton from '@/components/ui-kit/actions/UiButton'
import UiContainer from '@/components/ui-kit/layout/UiContainer'
import UiHeader from '@/components/ui-kit/layout/UiHeader'
import useAuth from '@/contexts/use-auth'
import { PencilIcon, PencilSquareIcon } from '@heroicons/react/24/outline'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Link,
  LoaderFunctionArgs,
  defer,
  useLoaderData,
  useParams,
} from 'react-router-dom'

export const accountPageLoader = async ({ params }: LoaderFunctionArgs) => {
  const [user] = await Promise.all([loadAuthPopulated()])
  return defer({ user })
}

export const userPageLoader = async ({ params }: LoaderFunctionArgs) => {
  const [user] = await Promise.all([findUserByIdPopulated(params.userId!)])
  return defer({ user })
}

export const employeePageLoader = async ({ params }: LoaderFunctionArgs) => {
  const [user] = await Promise.all([findUserByIdPopulated(params.employeeId!)])
  return defer({ user })
}

const UserPage = () => {
  const auth = useAuth()
  const { t } = useTranslation()
  const { userId, employeeId } = useParams()
  const loaderData = useLoaderData() as any
  const [user, setUserDetails] = useState<UserPopulatedResponse>(
    loaderData.user
  )

  useEffect(() => {
    if (user._id === auth.user._id) {
      auth.setUser(user)
    }
  }, [user])

  return (
    <UiContainer>
      <UiHeader
        avatar={{
          name: user.name,
          color: user.color,
          image: user.image,
          shape: 'circle',
        }}
        title={user.name}
        description={
          userId || employeeId
            ? t('page.description.user')
            : t('page.description.account')
        }
        mobileBackHref={
          userId ? '/users' : employeeId ? '/employees' : '/settings'
        }
        desktopBackHref={userId ? '/users' : employeeId ? '/employees' : ''}
        mobileAction={{
          icon: PencilSquareIcon,
          to: userId
            ? `/users/${user._id}/edit`
            : employeeId
            ? `/employees/${user._id}/edit`
            : `/account/edit`,
        }}
        desktopActions={
          <UiButton
            as={Link}
            variant="primary"
            icon={PencilIcon}
            label={
              userId || employeeId
                ? t('action.editUser')
                : t('action.editAccount')
            }
            to={
              userId
                ? `/users/${user._id}/edit`
                : employeeId
                ? `/employees/${user._id}/edit`
                : `/account/edit`
            }
          />
        }
      />
      <div className="py-6 md:py-8">
        <div className="space-y-6">
          <div className="grid grid-cols-1 grid-rows-1 items-start md:gap-6 lg:grid-cols-3 2xl:grid-cols-4 gap-6">
            <div className="lg:col-start-1">
              <UserGeneralCard user={user} />
            </div>
            <div className="lg:col-start-2 lg:col-span-2 lg:row-span-2 lg:row-end-2 2xl:col-start-2 2xl:col-span-3">
              <div className="space-y-6">
                <UserSecurityCard user={user} setUserDetails={setUserDetails} />
                <UserDangerzoneCard
                  user={user}
                  setUserDetails={setUserDetails}
                />
              </div>
            </div>
            <div className="lg:col-start-1">
              <UserMetadataCard user={user} />
            </div>
          </div>
        </div>
      </div>
    </UiContainer>
  )
}

export default UserPage
