import classNames from 'classnames';
import React, { useContext } from 'react';
import useAuth from '@/contexts/use-auth';

export type UiButtonProps = {
  label: string;
  variant:
    | 'primary'
    | 'default'
    | 'dark'
    | 'flat'
    | 'danger'
    | 'danger-outline'
    | 'success'
    | 'gray'
    | 'light-gray';
  icon?: React.ElementType;
  iconOnly?: boolean;
  className?: string;
  type?: 'submit' | 'reset' | 'button';
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  as?: React.ElementType;
  // forwarded
  href?: string;
  to?: string;
  disabled?: boolean;
  loading?: boolean;
  block?: boolean;
};

const UiButton = React.forwardRef<
  HTMLButtonElement,
  React.PropsWithChildren<UiButtonProps>
>(
  (
    {
      variant,
      label,
      icon,
      iconOnly,
      className,
      as,
      loading,
      block,
      ...props
    }: UiButtonProps,
    ref
  ) => {
    const auth = useAuth();
    const color = auth?.company?.appearance?.color || 'rose';

    // variant primary, dark, white, danger,
    // outline
    //

    // todo: disabled stats
    if (variant === 'primary') {
      className += ` border-transparent shadow-sm bg-${color}-600 text-white hover:bg-${color}-700 disabled:bg-${color}-600/50 focus:outline-none focus:ring-2 focus:ring-${color}-500 focus:ring-offset-2`;
    } else if (variant === 'danger') {
      className += ` border-transparent shadow-sm bg-red-600 text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 disabled:bg-red-600/50`;
    } else if (variant === 'danger-outline') {
      className += ` border-gray-300 bg-white text-rose-600 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-rose-500 focus:ring-offset-2 disabled:text-gray-700/50 disabled:bg-white`;
    } else if (variant === 'success') {
      className += ` border-transparent shadow-sm bg-green-100 text-green-700 hover:bg-green-200 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2`;
    } else if (variant === 'default') {
      className += ` border-gray-300 bg-white text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-${color}-500 focus:ring-offset-2 disabled:text-gray-700/50 disabled:bg-white`;
      // ' border-gray-300 bg-white text-gray-700 shadow-sm hover:bg-gray-50'
    } else if (variant === 'dark') {
      className += ` border-transparent bg-gray-800 text-white shadow-sm hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-gray-900 focus:ring-offset-2 disabled:bg-gray-900/50`;
    } else if (variant === 'flat') {
      className += ` border-transparent bg-transparent text-gray-700 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-${color}-500 focus:ring-offset-2 disabled:text-gray-700/50 disabled:bg-transparent`;
    } else if (variant === 'gray') {
      className += ` border-transparent shadow-sm bg-gray-200 text-gray-700 hover:bg-gray-300 disabled:bg-gray-200/50 focus:outline-none focus:ring-2 focus:ring-gray-100 focus:ring-offset-2`;
    } else if (variant === 'light-gray') {
      className += ` border-transparent shadow-sm bg-gray-100 text-gray-700 hover:bg-gray-200 disabled:bg-gray-100/50 focus:outline-none focus:ring-2 focus:ring-gray-100 focus:ring-offset-2`;
    }

    if (iconOnly) {
      className += ' p-2';
    } else {
      className += ' px-4 py-2 text-sm font-medium leading-6';
    }

    const Component = (as ? as : 'button') as React.ElementType;
    const Icon = icon as React.ElementType;

    return (
      <Component
        ref={ref}
        role='button'
        {...props}
        disabled={props.disabled || loading}
        className={classNames(
          'relative inline-flex justify-center items-center rounded-full border',
          block && 'w-full',
          className
        )}
      >
        {icon && (
          <>
            {iconOnly && (
              <>
                <span className='sr-only'>{label}</span>
                <Icon
                  className={classNames(
                    'h-5 w-5 m-0.5',
                    variant === 'default' && 'text-gray-500'
                  )}
                  aria-hidden='true'
                />
              </>
            )}
            {!iconOnly && (
              <>
                <Icon
                  className={classNames(
                    '-ml-1 mr-2 h-5 w-5',
                    variant === 'default' && 'text-gray-400'
                  )}
                  aria-hidden='true'
                />
              </>
            )}
          </>
        )}

        {loading && (
          <div
            className={classNames(
              block
                ? '-ml-1 mr-3'
                : 'absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
            )}
          >
            <svg
              className='animate-spin h-5 w-5 text-white'
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
            >
              <circle
                className='opacity-25'
                cx='12'
                cy='12'
                r='10'
                stroke='currentColor'
                strokeWidth='4'
              ></circle>
              <path
                className='opacity-75'
                fill='currentColor'
                d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
              ></path>
            </svg>
          </div>
        )}
        {!iconOnly && (
          <span className={loading && !block ? 'opacity-0' : ''}>{label}</span>
        )}
        {iconOnly && !icon && <span className='w-6'>{label}</span>}
      </Component>
    );
  }
);

export default UiButton;
