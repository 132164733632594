import { useTranslation } from 'react-i18next'
import useAuth from '@/contexts/use-auth'
import UiCard from '@/components/ui-kit/layout/UiCard'
import UiButton from '@/components/ui-kit/actions/UiButton'
import { useState } from 'react'
import { AxiosError } from 'axios'
import { handleValidationError } from '@/business/error-handler'
import {
  ArrowTopRightOnSquareIcon,
  CreditCardIcon,
} from '@heroicons/react/24/outline'
import classNames from 'classnames'
import { format, utcToZonedTime } from 'date-fns-tz'
import { getCurrentFnsLocale } from '@/business/date-utils'
import SubscriptionStatus from '@/business/dto/types/subscription-status'
import { createCompanyPortal } from '@/business/api/company.service'
import CompanyPopulatedResponse from '@/business/dto/responses/company-populated.response'

export type CompanyContractProps = {
  company: CompanyPopulatedResponse
  setCompany: React.Dispatch<React.SetStateAction<CompanyPopulatedResponse>>
  maxQuota: number
}

const CompanyContractCard = (props: CompanyContractProps) => {
  const auth = useAuth()
  const { t, i18n } = useTranslation()
  const [loading, setLoading] = useState<boolean>(false)

  const score = props.company.quotaUsed / props.maxQuota
  const numberFormatter = new Intl.NumberFormat(
    auth.company.localization.language
  )

  return (
    <UiCard>
      <UiCard.Header
        // "Ihre Rechnungsdetails ansehen und verwalten"
        // description="Irgendwo hinschreiben, was man im Kundenportal machen kann
        // (Abrechnungsinformationen inkl. Steuerrelevanter Informationen
        // anpassen, Kündigen, Plan wechseln, Zahlungsdaten hinterlegen /
        // ändern, Rechnungshistorie einsehen etc.)"
        title={t('card.title.companyContractPortal')}
        description={t('card.description.companyContractPortal')}
        icon={CreditCardIcon}
      />
      <UiCard.Body>
        <dl className="grid grid-cols-1 gap-0.5 overflow-hidden rounded-2xl text-center sm:grid-cols-2 2xl:grid-cols-4">
          <div className="flex flex-col justify-center items-center bg-gray-100 py-8 px-3">
            <dt className="text-sm font-semibold leading-6 text-gray-600">
              {t('common.monthlyReservations')}
            </dt>
            <dd className="order-first text-2xl font-semibold tracking-tight text-gray-900">
              <span
                className={classNames(
                  score <= 0.8 && 'text-emerald-600',
                  score <= 1 && 'text-amber-500',
                  score > 1 && 'text-rose-600'
                )}
              >
                {numberFormatter.format(props.company.quotaUsed)}
              </span>
              <span> / </span>
              <span>
                {props.maxQuota === -1
                  ? '∞'
                  : numberFormatter.format(props.maxQuota)}
              </span>
            </dd>
          </div>
          <div className="flex flex-col justify-center items-center bg-gray-100 py-8 px-3">
            <dt className="text-sm font-semibold leading-6 text-gray-600">
              {t('common.usageReset')}
            </dt>
            <dd className="order-first text-2xl font-semibold tracking-tight text-gray-900">
              {format(
                utcToZonedTime(
                  new Date(auth.company.subscription!.currentPeriodEnd),
                  auth.company.localization.timeZone
                ),
                'PP',
                {
                  locale: getCurrentFnsLocale(),
                  weekStartsOn: auth.company.localization.weekStartsOn,
                  timeZone: auth.company.localization.timeZone,
                }
              )}
            </dd>
          </div>
          <div className="flex flex-col justify-center items-center bg-gray-100 py-8 px-3">
            <dt className="text-sm font-semibold leading-6 text-gray-600">
              {t('common.selectedPlan')}
            </dt>
            <dd className="order-first text-2xl font-semibold tracking-tight text-gray-900">
              {t(`product.${auth.company.subscription!.productId}`)}
            </dd>
          </div>
          <div className="flex flex-col justify-center items-center bg-gray-100 py-8 px-3">
            <dt className="text-sm font-semibold leading-6 text-gray-600">
              {t('common.currentStatus')}
            </dt>
            <dd
              className={classNames(
                'order-first text-2xl font-semibold tracking-tight',
                auth.company.subscription!.cancelAtDateTimeInUtc === null &&
                  auth.company.subscription!.status ===
                    SubscriptionStatus.active
                  ? 'text-emerald-600'
                  : auth.company.subscription!.cancelAtDateTimeInUtc !== null ||
                    auth.company.subscription!.status ===
                      SubscriptionStatus.canceled
                  ? 'text-rose-600'
                  : 'text-amber-500'
              )}
            >
              {auth.company.subscription!.cancelAtDateTimeInUtc
                ? t(`subscription.canceled`)
                : t(`subscription.${auth.company.subscription!.status}`)}
            </dd>
          </div>
        </dl>
        <div>
          <UiButton
            variant="dark"
            type="button"
            label={t('action.manageSubscription')}
            icon={ArrowTopRightOnSquareIcon}
            loading={loading}
            onClick={() => {
              setLoading(true)
              createCompanyPortal({
                redirectUrl: window.location.href,
              })
                .then((x) => {
                  setTimeout(() => {
                    setLoading(false)
                  }, 600)
                  window.location.href = x.url
                })
                .catch((err: AxiosError) => {
                  setLoading(false)
                  handleValidationError(err, i18n)
                })
            }}
          />
          <p className="mt-3 text-xs text-gray-500">
            <span>Powered by </span>
            <a
              className={`text-gray-600 hover:text-gray-500 font-medium`}
              href="https://stripe.com"
              target="_blank"
              rel="noopener noreferrer nofollow"
            >
              stripe
            </a>
            <span>.</span>
          </p>
        </div>
      </UiCard.Body>
      <UiCard.Footer />
    </UiCard>
  )
}

export default CompanyContractCard
