import { useTranslation } from 'react-i18next'
import useAuth from '@/contexts/use-auth'
import UiCard from '@/components/ui-kit/layout/UiCard'
import UiButton from '@/components/ui-kit/actions/UiButton'
import { useEffect, useState } from 'react'
import {
  FormProvider,
  SubmitHandler,
  ValidateResult,
  useForm,
  useFormContext,
} from 'react-hook-form'
import UpdateUserRequest from '@/business/dto/requests/update-user.request'
import {
  createUser,
  updateUserById,
  validateUserEmail,
} from '@/business/api/user.service'
import { calcColor } from '@/business/api/image.service'
import toast from 'react-hot-toast'
import UiToast from '../ui-kit/presentation/UiToast'
import { AxiosError } from 'axios'
import { handleValidationError } from '@/business/error-handler'
import UiImageUpload from '../ui-kit/input/basic/UiImageUpload'
import UiInput from '../ui-kit/input/basic/UiInput'
import { useNavigate } from 'react-router-dom'
import CreateUserRequest from '@/business/dto/requests/create-user.request'
import Color from '@/business/dto/types/color'
import UiCombobox from '../ui-kit/input/basic/UiCombobox'
import Role from '@/business/dto/types/role'
import UiColorPicker from '../ui-kit/input/UiColorPicker'
import UserResponse from '@/business/dto/responses/user.response'

const mapToFormValue = (
  user?: UserResponse
): CreateUserRequest | UpdateUserRequest => {
  return {
    name: user?.name || '',
    description: user?.description || '',
    image: user?.image || null,
    color: user?.color || Color.gray,
    email: user?.email || '',
    role: user?.role || Role.member,
  }
}

export type UserCreateCardProps = {
  userId?: string
  type: 'user' | 'employee'
}

export const UserCreateCard = (props: UserCreateCardProps) => {
  const auth = useAuth()
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  const [loading, setLoading] = useState<boolean>(false)
  const form = useForm<CreateUserRequest>({
    defaultValues: mapToFormValue(undefined),
  })
  const name = form.watch('name')

  useEffect(() => {
    form.setValue('color', calcColor(name))
  }, [name])

  const onSubmit: SubmitHandler<CreateUserRequest> = (data) => {
    setLoading(true)
    createUser(data)
      .then((response) => {
        navigate(`/users/${response._id}`)
        toast.custom((toast) => (
          <UiToast
            toast={toast}
            type='success'
            title={t('toast.title.updated')}
            description={t('toast.description.userCreated')}
          />
        ))
      })
      .catch((err: AxiosError) => {
        setLoading(false)
        handleValidationError(err, i18n)
      })
  }

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <UserCreateUpdateCard
          loading={loading}
          user={undefined}
          type={props.type}
        />
      </form>
    </FormProvider>
  )
}

export type UserUpdateCardProps = {
  user: UserResponse
  userId?: string
  employeeId?: string
}

export const UserUpdateCard = (props: UserUpdateCardProps) => {
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  const [loading, setLoading] = useState<boolean>(false)
  const form = useForm<UpdateUserRequest>({
    defaultValues: mapToFormValue(props.user),
  })

  const onSubmit: SubmitHandler<UpdateUserRequest> = (data) => {
    const { role, ...rest } = data

    setLoading(true)
    updateUserById(props.user._id, role === Role.owner ? rest : data)
      .then((response) => {
        navigate(`/users/${response._id}`)
        toast.custom((toast) => (
          <UiToast
            toast={toast}
            type='success'
            title={t('toast.title.updated')}
            description={t('toast.description.userUpdated')}
          />
        ))
      })
      .catch((err: AxiosError) => {
        handleValidationError(err, i18n)
        setLoading(false)
      })
  }

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <UserCreateUpdateCard
          loading={loading}
          user={props.user}
          type={
            props.userId ? 'user' : props.employeeId ? 'employee' : 'account'
          }
        />
      </form>
    </FormProvider>
  )
}

type UserCreateUpdateCardProps = {
  loading: boolean
  user?: UserResponse
  type: 'user' | 'employee' | 'account'
}

const UserCreateUpdateCard = (props: UserCreateUpdateCardProps) => {
  const auth = useAuth()
  const navigate = useNavigate()
  const { t, i18n } = useTranslation()
  const form = useFormContext<CreateUserRequest | UpdateUserRequest>()

  const validateEmail = async (
    email: string | undefined
  ): Promise<ValidateResult> => {
    const response = await validateUserEmail(email!, props.user?._id)
    if (response.alreadyUsed) {
      return t('form.validation.alreadyUsed')!
    }

    return true
  }

  return (
    <UiCard>
      {/* <UiCard.Header
        title={t('common.general')}
        description={
          !props.user || props.user._id === auth.user._id
            ? t('description.accountGeneral')
            : t('description.userGeneral')
        }
        icon={CubeTransparentIcon}
      /> */}
      <UiCard.Body>
        <div className='space-y-6'>
          <UiImageUpload
            label={t('common.image')}
            avatar={{
              name: form.watch('name')!,
              color: form.watch('color')!,
              shape: 'circle',
            }}
            name='image'
            control={form.control}
          />
          <UiInput
            label={t('common.name')}
            type='text'
            name='name'
            control={form.control}
            autoFocus={true}
            rules={{
              required: {
                value: true,
                message: t('form.validation.required'),
              },
              minLength: {
                value: 3,
                message: t('form.validation.minLength', { x: 3 }),
              },
              maxLength: {
                value: 32,
                message: t('form.validation.maxLength', { x: 32 }),
              },
            }}
          />
          <UiInput
            label={t('common.emailAddress')}
            type='email'
            name='email'
            control={form.control}
            rules={{
              required: {
                value: true,
                message: t('form.validation.required'),
              },
              pattern: {
                value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                message: t('form.validation.email'),
              },
              maxLength: {
                value: 64,
                message: t('form.validation.maxLength', { x: 64 }),
              },
              validate: validateEmail,
            }}
          />
          <UiInput
            label={t('common.description')}
            type='text'
            description='Schreibe eine kurze Beschreibung, die unter dem Namen erscheint. Hierbei kann es sich um eine Jobbezeichnung handeln.'
            name='description'
            control={form.control}
            rules={{
              maxLength: {
                value: 64,
                message: t('form.validation.maxLength', { x: 64 }),
              },
            }}
          />
          <UiColorPicker
            label={t('common.color')}
            control={form.control}
            name='color'
            rules={{ required: true }}
          />
          <hr className='bg-gray-100' />
          {!auth.user.resflowStaff && (
            <UiCombobox
              label={t('common.role')}
              disabled={
                props.user &&
                (props.user.role === Role.owner ||
                  props.user._id === auth.user._id)
              }
              description={
                props.user && props.user.role === Role.owner
                  ? 'Der Besitzer kann nicht verändert werden.'
                  : props.user && props.user._id === auth.user._id
                  ? 'Die eigene Rolle kann nicht verändert werden.'
                  : ''
              }
              options={[
                {
                  value: 'owner',
                  label: t('common.owner'),
                  hidden: true,
                },
                { value: 'admin', label: t('common.admin') },
                { value: 'member', label: t('common.member') },
              ]}
              control={form.control}
              name='role'
            />
          )}
        </div>
      </UiCard.Body>
      <UiCard.Footer>
        <UiButton
          label={t('action.cancel')}
          type='button'
          variant='flat'
          onClick={
            props.user
              ? () =>
                  navigate(
                    props.type === 'user'
                      ? `/users/${props.user?._id}`
                      : props.type === 'employee'
                      ? `/employees/${props.user?._id}`
                      : '/account'
                  )
              : () =>
                  navigate(
                    props.type === 'user'
                      ? '/users'
                      : props.type === 'employee'
                      ? '/employees'
                      : '/account'
                  )
          }
        />
        <UiButton
          label={props.user ? t('action.save') : t('action.createAndInvite')}
          type='submit'
          variant={props.user ? 'dark' : 'primary'}
          loading={props.loading}
          disabled={!form.formState.isDirty}
        />
      </UiCard.Footer>
    </UiCard>
  )
}
